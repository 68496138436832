module.exports = {
  lang: "en-US",
  languageName: "English",
  title: "Xianghu's Blog",
  subtitle: "~ Feel the Life, Feel the World ~",
  description: "Explore the universe of programming",
  homePage: "Home",
  work: "Works",
  tags: "Tags",
  tag: "Tag",
  about: "About",
  resume: "Resume",
  wechat: "WeChat",
  alternativeLanguage: "中文",
  permalink: "Permalink",
  alternativePage: "本文中文版本",
  builtWith: "Built with",
  builtWithEnd: "",
  allPosts: "All Posts",
  tagList: "Tag List",
  aboutTitle: "About Xianghu's Blog",
  aboutPart1: "Xianghu's Blog",
}
